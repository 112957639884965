import ProcessStages from '../components/ProcessStages'

import HouseDataForm from '../components/HouseDataForm'

import './Application.css'

const stages = ['Kontakinforasjon', 'Bolig og lån', 'Selge andel'];

export default function Application() {
    return <div>
        <h1>Bålig</h1>
        <ProcessStages stages={stages} currentStage={1} />
        <HouseDataForm />
    </div>
}