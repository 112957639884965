import React, { useState } from 'react';

import TextInput from './TextInput';

import './HouseDataForm.css'

const HouseDataForm = () => {
  // State to store input values
  const [price, setPrice] = useState('');
  const [fellesgjeld, setFellesgjeld] = useState('');


  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="user-form-container">
      <h2>Prisantydning</h2>
      <form onSubmit={handleSubmit}>
        
        <div className="form-group">
          <TextInput
            label="Prisantydning"
            value={price}
            onChange={(val) => setPrice(val)}
          />
        </div>

        <div className="form-group">
          <TextInput
            label="Fellesgjeld"
            value={fellesgjeld}
            onChange={(val) => setFellesgjeld(val) }
          />
        </div>

        <div className="form-group">
          <TextInput
            label="Fellesgjeld ink fellesgjeld"
            value={fellesgjeld + price}
            onChange={(val) => {} }
          />
        </div>

        <h2>Boliglån</h2>
        <div className="form-group">
          <TextInput
            label="hvor mye boliglån har dere?"
            value={fellesgjeld}
            onChange={(val) => {} }
          />
        </div>

        <h2>Eierandel</h2>
        <div className="form-group">
          <TextInput
            label="Din eierandel"
            value={'50%'}
            onChange={(val) => {} }
          />
        </div>

        <h2>Finansieringsbevis</h2>
        <div className="form-group">
          <TextInput
            label="hvor mye hard du i finansieringsbevis"
            value={''}
            onChange={(val) => {} }
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="save-button">
          Gå videre
        </button>
      </form>

    </div>
  );
};

export default HouseDataForm;
