import React, { useState } from 'react';
//import Auth0LockPasswordless from 'auth0-lock-passwordless';
import auth0 from 'auth0-js';

import TextInput from './TextInput';

import './UserDataForm.css'

const UserDataForm = () => {
  // State to store input values
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [otpSent, setOtpSent] = useState(false);

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (email && phone && agreed) {
      setSubmitted(true);
      console.log('Form Submitted:', { email, phone });
      //window.location = '/house'

      sendOtp();
    } else {
      alert('Please fill all fields and agree to the rules');
    }
  };

  const passwordlessOptions = {
    allowedConnections: ['email'],
    passwordlessMethod: 'code',
    autoclose: true,
    auth: {
    //   redirectUrl: 'http://localhost:3000/callback',   
    //   responseType: 'token id_token',
    //   redirect: false,
    //   params: {
    //     scope: 'openid email'
    //   }
    }
  }

  const clientId = 'ilc7ZV2PB4hYSfj9lvPWNzW4Chn4u1ct'
  const domain = 'hili-dev.eu.auth0.com'
  
  // const lockPasswordless = new Auth0LockPasswordless(
  //  'ilc7ZV2PB4hYSfj9lvPWNzW4Chn4u1ct',
  //  'hili-dev.eu.auth0.com',
  //  passwordlessOptions
  // );

  var webAuth = new auth0.WebAuth({
    clientID: clientId,
    domain: domain,
    redirectUri: 'https://home-app.dev.hilitech.dev/callback',
    responseType: 'token id_token'
  });

  const sendOtp = () => {
    const userData = {
      email: email,
      phone: phone,
      firstName: name,
      address: address
    }

    const userCreateUrl = 'https://home-api.dev.hilitech.dev/api/users/create'
    
    fetch(userCreateUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    }).then(response => {
      console.log(response);
      if (!response.ok) {
        console.log(response.statusText)
        throw new Error('Error: ' + response.statusText);
      }
      if (response.ok) {
        webAuth.passwordlessStart({
          connection: 'email',
          send: 'code',
          email: email
        }, function (err,res) {
          console.log('err', err);
          console.log('res', res);
          setOtpSent(true)
        });
      }
    })
    .catch((error) => {
      console.error('Error:', error); // Handle any errors that occurred
    });
  }

  // const sendOtp1 = () => {
  //   lockPasswordless.emailcode({closable: false}, function(error, profile, id_token, access_token, state, refresh_token) {
  //     console.log(profile);
  //     console.log(access_token);
  //     console.log('error', error);
  //     if (!error) {
  //       console.log('error', error);
  //       alert("User has logged in");
  //     }
  //   });
  // }

  // const sendOtp2 = () => {
  //   lockPasswordless.passwordlessStart(
  //     {
  //       connection: 'email', // Connection for email
  //       send: 'code', // Send the login code to the user's email
  //       email: email,
  //     },
  //     (err) => {
  //       if (err) {
  //         console.error('Error sending OTP:', err);
  //       } else {
  //         console.log('OTP sent successfully!');
  //         setOtpSent(true);
  //       }
  //     }
  //   );
  // };

  // const verifyOtp = () => {
  //   lockPasswordless.passwordlessVerify(
  //     {
  //       connection: 'email',
  //       email: email,
  //       verificationCode: otpCode,
  //     },
  //     (err, profile, id_token) => {
  //       if (err) {
  //         console.error('OTP verification failed:', err);
  //       } else {
  //         console.log('Login successful! User Profile:', profile);
  //         // Handle successful authentication here, like storing the token
  //         console.log('ID Token:', id_token);
  //       }
  //     }
  //   );
  // };

  const verifyOpt = () => {
    webAuth.passwordlessLogin({
      connection: 'email',
      email: email,
      verificationCode: otpCode
    }, function (err,res) {
      console.log('err', err);
      console.log('res', res);
    });
  }

  return (
    <div className="user-form-container">
      <h2>Hei,</h2>
      <h3>Vi trenger litt mer kontaktinformasjon for å behandle søknaden</h3>

      {otpSent && (
        <div>
          <div className="form-group">
            <TextInput
              label="OTP code"
              value={otpCode}
              onChange={(val) => setOtpCode(val)}
            />
          </div>
          <button type="button" className="save-button" onClick={verifyOpt}>
            Verify
          </button>
        </div>
      )}

      {!otpSent &&
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <TextInput
            label="Address"
            value={address}
            onChange={(val) => setAddress(val)}
          />
        </div>

        <div className="form-group">
          <TextInput
            label="Name"
            value={name}
            onChange={(val) => setName(val)}
          />
        </div>

        <div className="form-group">
          <TextInput
            label="E-post"
            value={email}
            onChange={(val) => setEmail(val)}
          />
        </div>

        <div className="form-group">
          <TextInput
            label="Mobilnummer"
            value={phone}
            onChange={(val) => setPhone(val)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="agree">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              required
            />
            Jeg godtar at HILI kan kontakte meg vedrørende min søknad
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="agree">
            <input
              type="checkbox"
              id="agree"
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
              required
            />
            Jeg godtar vilkårene
          </label>
        </div>

        <button type="submit" className="save-button">
          Gå videre
        </button>
      </form>
      }
    </div>
  );
};

export default UserDataForm;
